/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  DriverSortField,
  InvoiceSortField,
  PricingRulesSortField,
  ShipmentTemplatesSortField,
  ShipmentsSortField,
  SortingDirection,
} from "@api/graphql/generated/generated-types";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import { Tooltip } from "@src/common/components";
import { PartialShipmentsSorting } from "./SortingOptionsDropdown";
import { useTableControl } from "../context/TableControlContext";
import { find } from "lodash";

export type SortFieldOption = {
  value:
    | ShipmentsSortField
    | PricingRulesSortField
    | ShipmentTemplatesSortField
    | DriverSortField
    | InvoiceSortField;
  label: string;
};

type SortFieldProps = {
  sort: PartialShipmentsSorting;
  index: number;
  onRemove: (index: number) => void;
  onUpdate: any;
  sortFieldOptions: SortFieldOption[];
};

export default function SortField({
  sort,
  index,
  onRemove,
  onUpdate,
  sortFieldOptions: sortFields,
}: SortFieldProps) {
  const [selectedField, setSelectedField] = useState<any>(sort.field);
  const [selectedDirection, setSelectedDirection] = useState<any>(
    sort.direction || SortingDirection.Asc
  );
  const { sortFields: selectedFieldsFromContext } = useTableControl();

  useEffect(() => {
    setSelectedField(sort.field);
    setSelectedDirection(sort.direction || SortingDirection.Asc);
  }, [sort]);

  useEffect(() => {
    if (selectedField) {
      onUpdate(index, { field: selectedField, direction: selectedDirection });
    }
  }, [selectedField, selectedDirection, index]);

  const selectedFieldNames = selectedFieldsFromContext.map(
    (fieldObj) => fieldObj.field
  );

  return (
    <div className="flex gap-2">
      <div style={{ width: 250 }}>
        <Listbox value={selectedField} onChange={setSelectedField}>
          <div className="relative mt-1">
            <Listbox.Button className="relative border w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">
                {selectedField
                  ? find(sortFields, ["value", selectedField])?.label
                  : "Choose a field"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {sortFields
                  .filter((field) => !selectedFieldNames.includes(field.value))
                  .map((field, fieldIdx) => {
                    return (
                      <Listbox.Option
                        key={fieldIdx}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                            active
                              ? "bg-primary-100 text-primary-900"
                              : "text-gray-900"
                          }`
                        }
                        value={field.value}
                      >
                        <span className={`block truncate font-normal`}>
                          {field.label}
                        </span>
                      </Listbox.Option>
                    );
                  })}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>

      <div className="col-span-1 m-auto hover:bg-slate-100 hover:cursor-pointer rounded-full">
        {selectedDirection === SortingDirection.Asc ? (
          <Tooltip content="Descending">
            <div
              className="h-full w-full rounded-full p-2"
              onClick={() => setSelectedDirection(SortingDirection.Desc)}
            >
              <ArrowDownIcon className="h-5 w-5" />
            </div>
          </Tooltip>
        ) : (
          <Tooltip content="Ascending">
            <div
              className="h-full w-full rounded-full p-2"
              onClick={() => setSelectedDirection(SortingDirection.Asc)}
            >
              <ArrowUpIcon className="h-5 w-5" />
            </div>
          </Tooltip>
        )}
      </div>

      <Tooltip content="remove">
        <div className="mt-1 col-span-1 m-auto hover:bg-slate-100 hover:cursor-pointer rounded-full">
          <div
            className="h-full w-full rounded-full p-2"
            onClick={() => onRemove(index)}
          >
            <TrashIcon className="h-5 w-5" />
          </div>
        </div>
      </Tooltip>
    </div>
  );
}
